<template>
  <l-map ref="map" style="height: 100vh" :zoom="zoom" :center="center">
    <!-- <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer> -->
    <!-- <l-control-layers position="topright"></l-control-layers> -->
    <l-tile-layer
      v-for="tileProvider in tileProviders"
      :key="tileProvider.name"
      :name="tileProvider.name"
      :visible="tileProvider.visible"
      :url="tileProvider.url"
      :attribution="tileProvider.attribution"
      layer-type="base"
    />

    <l-marker
      v-for="marker in markers"
      :key="marker.id"
      :lat-lng="marker.position"
      :icon="marker.icon"
      @click="alertBox(marker)"
    >
      <l-popup :content="marker.tooltip" />
    </l-marker>
  </l-map>
</template>

<script>
import { formatDevicetoMap } from '@/utils/map'
export default {
  name: 'Map',

  data () {
    return {
      // url: "https://api.mapbox.com/styles/v1/iaunn/cjzlbq1tn02zi1cmwhdy4bodh/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaWF1bm4iLCJhIjoiY2owMXJuNjBsMDZuYjJ3b3h2ajA3NjBoaiJ9.94FRMLxLh0ByP7ROCRBSTg",
      // url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // url: "https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
      url: 'http://ms.longdo.com/mmmap/tile.php?zoom={z}&x={x}&y={y}&key=9542b4a7fa6f2e9b0e3846ea64e2ff83&proj=epsg3857&HD=1',
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 8,
      tileProviders: [
        {
          name: 'OpenStreetMap',
          visible: false,
          attribution:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
        },
        {
          name: 'Longdo Map',
          visible: true,
          url: 'http://ms.longdo.com/mmmap/tile.php?zoom={z}&x={x}&y={y}&key=9542b4a7fa6f2e9b0e3846ea64e2ff83&proj=epsg3857&HD=1',
          attribution: ''
        },
        {
          name: 'Mapbox Map',
          visible: false,
          url: 'https://api.mapbox.com/styles/v1/iaunn/cjzlbq1tn02zi1cmwhdy4bodh/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiaWF1bm4iLCJhIjoiY2owMXJuNjBsMDZuYjJ3b3h2ajA3NjBoaiJ9.94FRMLxLh0ByP7ROCRBSTg',
          attribution: ''
        }
        // {
        //   name: "Google Map",
        //   visible: false,
        //   url: "https://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
        //   attribution: "",
        // },
      ],
      center: [13.7717449, 100.9163648],
      markers: [],
      debug: {
        gps_id: '9170411271',
        name: 'TEST',
        geo: { latitude: 12.048315, longitude: 99.85072, bearing: 201 },
        speed: { knots: 31.75, kmh: 58.801, mph: 36.544 },
        status: { power: true, engine: true },
        valid: true,
        timestamp: '2021-12-03T14:37:29.000Z'
      }
    }
  },
  mounted () {
    if (this.$route.params?.id) {
      this.subscribe(this.$route.params?.id)
      window.gtag('event', 'gps_view', { gps_id: this.$route.params?.id })
    } else {
      window.gtag('event', 'gps_view', { gps_id: 'home' })
    }

    // this.isDebug();
  },
  methods: {
    isDebug () {
      console.log('5555')
      const data = this.debug
      console.log(data, this.debug)
      this.markers = [formatDevicetoMap(data)]
      const location = [data.geo.latitude, data.geo.longitude]
      const zoom = 50
      this.$refs.map.mapObject.setView(location, zoom)

      // this.center = [data.geo.latitude, data.geo.longitude];

      // setTimeout(() => {
      //   this.zoom = 15;
      // }, 500);
    },
    subscribe (id) {
      this.$socket.emit('start', id)
      this.sockets.subscribe('start', (data) => {
        if (data) {
          this.markers = [formatDevicetoMap(data)]
          const location = [data.geo.latitude, data.geo.longitude]
          const zoom = 15
          this.$refs.map.mapObject.setView(location, zoom)
        }
      })

      this.sockets.subscribe('event', (data) => {
        if (data) {
          this.markers = [formatDevicetoMap(data)]
        }
      })
    },
    alertBox (marker) {
      console.log(marker)
    }
  }
}
</script>

<style scoped>
</style>
