// const moment = require('moment')
const dayjs = require('dayjs')
const { divIcon } = require('leaflet')

function generateIcon (data, detail) {
  let img
  const name = data.name || data.device?.name || data.device?._id || data._id || data.gps_id
  const speed = data.speed.kmh
  const bearing = data.geo.bearing
  const engine = data.status.engine
  const power = data.status.power
  if (engine && power) {
    if (speed > 100) {
      img = '/image/orange_tran.png'
    } else if (speed > 50) {
      img = '/image/green_tran.png'
    } else {
      img = '/image/blue_tran.png'
    }
  } else if (!engine && !power) {
    img = '/image/red_tran.png'
  } else if (!engine && power) {
    if (speed > 5) {
      img = '/image/green_tran.png'
    } else {
      img = '/image/gray_tran.png'
    }
  } else if (engine && !power) {
    img = '/image/yellow_tran.png'
  }

  // img = '/image/car.png'
  const detailTable = (detail ? `<div style="font-family: 'IBM Plex Sans Thai'; margin-top: 3px; width: 100px; box-shadow: rgba(100, 100, 111, 0.5) 0px 7px 29px 0px; background: #ffffffff; font-size: 15px;">${name}<br>(${Math.ceil(data.speed.kmh)} km/h)</div>` : '')
  const html = `<div style="width: 100px; text-align: center; font-weight: bold; "> <div style="width: 100%; height: 50px; "><img src="${img}" width="25px" style="transform: rotate(${bearing}deg);"></div>${detailTable}</div>`
  return {
    className: 'my-div-icon',
    html,
    popupAnchor: [-12, -40],
    iconAnchor: [62, 25]
  }
}

function formatDevicetoMap (item, detail = true) {
  return {
    id: item.gps_id || item._id || item.__id || item.device._id,
    icon: divIcon(generateIcon(item, detail)),
    position: { lat: item.geo.latitude, lng: item.geo.longitude },
    tooltip: getTableDetail(item),
    data: item
  }
}

function formatEventtoMap (item) {
  return {
    id: item._id || item.__id || item.device._id,
    icon: divIcon(eventIcon(item)),
    position: { lat: item.geo.latitude, lng: item.geo.longitude },
    tooltip: getTableDetail(item),
    data: item
  }
}

function eventIcon (item) {
  const img = '/img/markers/stop.png'
  return {
    className: 'my-div-icon',
    html: `<div style="width: 15px; text-align: center;"> <img src="${img}" width="15px"></div>`,
    popupAnchor: [0, -15],
    iconAnchor: [7, 15]
  }
}

function getTableDetail (item) {
  const time = getFormatTime(item.timestamp)
  let text = `<table class="table" style="font-size: 15px; ">
  <tr><td style="font-weight: bold; padding-right: 10px;">รหัส: </td><td>${item.gps_id || item._id || item.device?._id}</td></tr>`
  if (item.name) {
    text += `<tr><td style="font-weight: bold; padding-right: 10px;">ชื่อ: </td><td>${item.name || item.device?.name || '-'}</td></tr>`
  }
  text += `<tr><td style="font-weight: bold; padding-right: 10px;">ตำแหน่ง: </td>
  <td>
      <a href="http://maps.google.com/maps?q=${item.geo.latitude},${item.geo.longitude}&amp;t=m" target="_blank" style="text-decoration: none;">
      ${item.geo.latitude.toFixed(5)} °, ${item.geo.longitude.toFixed(5)} °
      </a>
  </td>
  </tr>
  <tr><td style="font-weight: bold; padding-right: 10px;">มุม: </td><td>${item.geo.bearing}°</td></tr>
  <tr><td style="font-weight: bold; padding-right: 10px;">ความเร็ว: </td><td>${Math.ceil(item.speed.kmh)} km/h</td></tr>
  <tr><td style="font-weight: bold; padding-right: 10px;">เวลา: </td><td>${time}</td></tr>
  </table>`

  return text
}

function getFormatTime (time) {
  // return moment(time).format('DD/MM/YYYY HH:mm:ss')
  // return time
  return dayjs(time).format('DD/MM/YYYY HH:mm:ss')
}

function msToTime (duration) {
  let seconds = parseInt((duration / 1000) % 60)
  let minutes = parseInt((duration / (1000 * 60)) % 60)
  let hours = parseInt((duration / (1000 * 60 * 60)) % 24)

  hours = (hours < 10) ? '0' + hours : hours
  minutes = (minutes < 10) ? '0' + minutes : minutes
  seconds = (seconds < 10) ? '0' + seconds : seconds

  return hours + ':' + minutes + ':' + seconds
}

function downloadLink (blob, filename) {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
}

export { generateIcon, formatDevicetoMap, getFormatTime, getTableDetail, formatEventtoMap, msToTime, downloadLink }
